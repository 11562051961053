<template>
  <div class="hp-categories">
    <div class="d-md-flex" v-if="!_IsEmpty(hpCategories)">

      <nuxt-link
          v-for="category in hpCategories"
          :to="parseLink(category?.cat?.url)"
          class="hp-cat d-flex align-items-center justify-content-center cover"
          :style="`background-image: url(${category?.image?.node?.sourceUrl}.webp)`"
      >
        <div class="hp-cat__inner">

          <h3 class="title-h4 text--white">{{ category?.title }}</h3>
          <div class="subtitle text--white">{{ category?.subtitle }}</div>
          <div class="subtitle-bold text--red red-underline red-underline--short2">{{ category?.text }}</div>
        </div>
      </nuxt-link>
    </div>
  </div>
</template>

<script setup lang="ts">
const {currentPage} = useGlobalStore();
const {hpCategories} = currentPage?.template?.homepage;
</script>
